//
//  :react & redux:
import React from "react";
import { Helmet } from "react-helmet";

//
//  :components:
import { Content } from "../../components/Content";
import { AppleWatchIcon, CameraIcon, CarIcon, ClipboardInCicleIcon, ComputerIcon, ControllerIcon, DroneIcon, HammerInCircleIcon, HeadphonesIcon, PersonalCareIcon, PhoneIcon, SmartHomeIcon, TickInCircleIcon, TvIcon, WasherIcon, WhiteGoodsIcon } from "./svgs/Svgs";
import { TeknosaSyndicationForm } from "./Form";

export const TeknosaLanding = () => {

  const headerContent = () => {
    return <>
      <div className="md:w-4/5 w-[90%] flex items-center md:p-[6.25rem] p-4">
        <div id="header" className="w-full h-fit flex flex-col items-center">
          <img className="w-[14rem]" src={"https://cdn.pointandplace.com/best-buy-landing-page/images/eyekandy-logo-white.png"} alt="eyekandy logo" />
          <div className="mt-4 max-w-[54rem]">
            <p className="text-[35px] md:text-[40px] text-center">Our preferred partner for the production and syndication of AR and 3D product models</p>
          </div>
          <div className="flex md:flex-row flex-col justify-evenly w-full mt-4 teknosa-font-reg">
            <p className="max-w-[23rem] mb-5 md:mb-0 text-center">Teknosa and eyekandy have collaborated to help Brand Partners deliver industry leading AR and 3D models. eyekandy is a global leader in creative storytelling, strategy and activation and they work with leading brands to produce high quality AR and 3D product models. </p>
            <p className="max-w-[23rem] text-center">This page is designed to provide brands with all the information they need to generate AR and 3D modeling using our preferred process. You can find everything from relevant contact details to pricing (using the calculator below). </p>
          </div>
        </div>
      </div>
    </>
  }

  const serviceUsage = () => {
    return <>
      <div className="w-full h-fit flex flex-col justify-center items-center bg-[white] md:p-9 p-4">
        <div className="md:w-4/5 w-[90%] h-fit flex flex-col items-center">
          <p className="text-[#f58220] text-[37px] teknosa-font-med text-center">Where can these services be used?</p>
          <p className="text-black teknosa-font-reg text-center max-w-[38rem] text-[15px]">These services can be used on Desktop browser (3D only), mobile browser and on mobile through the Teknosa App. You can see the services in action below:</p>
        </div>
        <div className="flex md:flex-row flex-col w-4/5 md:w-[98%] justify-evenly xl:justify-center mt-2 md:mt-9 teknosa-font-reg">
          <div className="flex flex-col items-center text-black justify-between xl:ml-2">
            <p className="text-center max-w-[37rem] my-4">This provides consumers with additional product information, specifications, which can help them make better-informed purchasing decisions. </p>
            <video className="w-[32rem] md:w-[35rem] lg:w-[45rem] xl:w-[45rem] rounded-lg drop-shadow-lg" autoPlay muted loop>
              <source src="https://cdn.pointandplace.com/teknosa-landing-page/videos/teknosa-3d-example.qt" type="video/mp4"></source>
              Your browser does not support the video element. Kindly update it to latest version.
            </video>
          </div>
        </div>
        <div className="text-center text-black mt-4">
          <p className="max-w-[30rem]">By providing consumers with an improved shopping experience, AR has the potential to drive sales and increase revenue.</p>
        </div>
      </div>
    </>
  }

  const supportedCategories = () => {
    return <>
      <div className="w-full h-fit flex flex-col justify-center items-center md:p-9 p-3">
        <p className="text-[37px] teknosa-font-med max-w-[44rem] text-center">Products categories that can be supported by AR and 3D</p>

        <div className="flex md:w-4/5 w-[90%] justify-evenly mt-9">
          <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-5 sm:gap-9 flex justify-center">
            <div className="flex flex-col justify-evenly items-center text-center">
              <TvIcon></TvIcon>
              <p className="teknosa-font-reg">Television</p>
            </div>
            <div className="flex flex-col justify-evenly items-center text-center">
              <ComputerIcon></ComputerIcon>
              <p className="teknosa-font-reg">Computer and tablets</p>
            </div>
            <div className="flex flex-col justify-evenly items-center text-center">
              <WasherIcon></WasherIcon>
              <p className="teknosa-font-reg">Home Appliances</p>
            </div>
            <div className="flex flex-col justify-evenly items-center text-center">
              <PhoneIcon></PhoneIcon>
              <p className="teknosa-font-reg">Phone</p>
            </div>
            <div className="flex flex-col justify-evenly items-center text-center">
              <PersonalCareIcon></PersonalCareIcon>
              <p className="teknosa-font-reg">Personal Care</p>
            </div>
            <div className="flex flex-col justify-evenly items-center text-center">
              <WhiteGoodsIcon></WhiteGoodsIcon>
              <p className="teknosa-font-reg">White Goods</p>
            </div>
          </div>
        </div>
      </div>
    </>
  }

  const stepsToGetStarted = () => {
    return <>
      <div className="w-full h-fit flex flex-col justify-center items-center p-9 bg-white">
        <p className="text-[37px] teknosa-font-med max-w-[44rem] text-center text-[#f58220]">4 steps to get started:</p>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-8 mt-9">

          <div className="sm:w-[23rem] sm:h-[20rem] w-[15rem] border border-black rounded-md p-6 flex flex-col items-center justify-evenly text-center">
            <ClipboardInCicleIcon></ClipboardInCicleIcon>
            <p className="text-black mt-3 sm:mt-0 ">1. Please fill out the cost calculator and book a call with our 3D/AR expert. Or Contact us at teknosa@eyekandy.com</p>
          </div>

          <div className="sm:w-[23rem] sm:h-[20rem] w-[15rem] border border-black rounded-md p-6 flex flex-col items-center justify-evenly text-center">
            <img src={"https://cdn.pointandplace.com/best-buy-landing-page/images/url-in-circle.png"} alt="" />
            <p className="text-black mt-3 sm:mt-0">2. Select your products​ and send us the URL from the Teknosa product page.</p>
          </div>

          <div className="sm:w-[23rem] sm:h-[20rem] w-[15rem] border border-black rounded-md p-6 flex flex-col items-center justify-evenly text-center">
            <HammerInCircleIcon></HammerInCircleIcon>
            <p className="text-black mt-3 sm:mt-0">3. We produce the AR/3D Models within approximately 10 working days to Teknosa specifications.​</p>
          </div>

          <div className="sm:w-[23rem] sm:h-[20rem] w-[15rem] border border-black rounded-md p-6 flex flex-col items-center justify-evenly text-center">
            <TickInCircleIcon></TickInCircleIcon>
            <p className="text-black mt-3 sm:mt-0">4. eyekandy notifies you when your AR/3D Models are Live on Teknosa.com​</p>
          </div>

        </div>
      </div>
    </>
  }

  const renderPage = () => {
    return <>
      {headerContent()}
      {serviceUsage()}
      {supportedCategories()}
      {stepsToGetStarted()}
      <TeknosaSyndicationForm></TeknosaSyndicationForm>
    </>
  }

  return <>
    <Helmet>
      <title>Eyekandy - Teknosa Syndication</title>
      <meta property="og:title" content="Eyekandy - Teknosa Syndication" />
      <meta name="description" content="" />
      <meta name="og:description" content="" />
    </Helmet>
    <Content classes="w-full flex flex-col items-center bg-[#f58220] teknosa-font-med">
      {renderPage()}
    </Content>
  </>
}